<style scoped>
  .pop-doc-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    border-radius: 8px;
    z-index: 9;
  }
</style>

<template>
  <div>
    <span @click="status.print = true">打开</span>
    <div v-if="status.print" class="pop-doc-page">
      <print @quit="status.print = false" :source="source" />
    </div>
  </div>
</template>

<script>
import Print from '@/components/base/file/print.vue'

export default {
  components: { Print },
  data () {
    return {
      status: {
        print: false
      },
      source: [
        '/static/test/1.jpg',
        '/static/test/2.jpg',
        '/static/test/3.jpg',
        '/static/test/4.jpg',
        '/static/test/5.jpg'
      ]
    }
  }
}
</script>
